import Camera from '../components/Camera'
import SceneCanvas from '../components/SceneCanvas'
import SceneEnvironment from '../components/SceneEnvironment'
import { Html, Image } from '@react-three/drei'
import { useMemo } from 'react'
import SantaFigure from '../components/SantaFigure'
import CollectibleItem from '../components/CollectibleItem'
import { useSelector } from 'react-redux'
import * as Constants from '../Constants'
import InformationPromptView from '../views/InformationPromptView'
import { setAnimationStart, setAudioSuspended, setIsIOSReady, setIsSupportedBrowser, setPlayAgainSafari, setStartedOnSafariIOS } from '../../../../../store/slices/dataMessageSlice';
import { changeCameraTargetToSanta, changeCameraTargetToSide } from '../slices/cameraSlice';
import { useDispatch } from 'react-redux';
import { useRef, useEffect } from 'react'
import { testSanta } from '../slices/santaFigureSlice'


export default function MilkaTestScene({ children, lang }) {
    const dispatch = useDispatch();

    const cameraMode = useSelector(state => state.camera.mode)
    const language = useSelector(state => state.language.value)
    const isWinner = useSelector(state => state.user.isWinner)
    const isGiver = useSelector(state => state.user.isGiver)

    const isSupportedBrowser = useSelector((state) => state.dataMessage.isSupportedBrowser);
    const audioSuspended = useSelector((state) => state.dataMessage.audioSuspended);
    const dataSound = useSelector((state) => state.dataMessage.sound);
    const playAgainSafari = useSelector((state) => state.dataMessage.playAgainSafari);

    const audioRef = useRef(null);
    const audioRefDom = useRef(null);

    const cameraInitialAngles = useMemo(() => {
        return Constants.CameraInitialAngles[language]
    }, [language])

    useEffect(() => {
        if( playAgainSafari ) {
                dispatch(setIsSupportedBrowser(true));
                setTimeout(() => {
                    dispatch(changeCameraTargetToSanta());
                }, 1000);
                setTimeout(() => {
                    const audioElement = audioRefDom.current;
                    if (audioElement) {
                        audioElement.src = dataSound;
                        audioElement.load();   
                    }
                    audioElement.play();                    
                    dispatch( setAnimationStart( true ));
                    dispatch(testSanta(true));
                    dispatch(setPlayAgainSafari(false));         
                }, 2000);            
        }
    }, [playAgainSafari])

    const handleIOSOverlay = () => {
        dispatch( setIsSupportedBrowser( true ));

        setTimeout(() => {
            dispatch(changeCameraTargetToSanta());
        }, 750);

        const audioElement = audioRefDom.current;
        if (audioElement) {
            console.log('dataSound :>> ', dataSound);
            audioElement.src = dataSound;
            audioElement.load(); // Load the audio data

            // audioElement.oncanplay = () => {
            //   setIsAudioReady(true); // Audio is ready to play
            //   setIsAudioLoading(false); // Stop showing loading
            // };      
            
            // audioElement.onerror = (error) => {
            //   console.error('Error loading audio:', error);
            //   setIsAudioLoading(false);
            // };
          }
        setTimeout(() => {            
            audioElement.play();
            dispatch(setStartedOnSafariIOS(true));
            dispatch( setAnimationStart( true ));
            dispatch(testSanta(true));         
        }, 2000);        
    }

    

    return (
        <>              
            <audio ref={audioRefDom} preload="auto" />
            { !isSupportedBrowser && 
            <div className='iOsOverlay'>
                <button className='btn' onClick={ handleIOSOverlay }>Play</button>
            </div> }
            <SceneCanvas startPosition={ [0, 2, 0] }>
                <Camera 
                    horizontalAngle={ cameraInitialAngles.horizontal } 
                    verticalAngle={ cameraInitialAngles.vertical }
                />

                <ambientLight />

                <SceneEnvironment texture="/textures/scene15.jpg" />

                <SantaFigure htmlAudioRef={ audioRef } />


                <>
                    <CollectibleItem lang={lang} position={ [4.65, -1.2, -.85] } rotation={ -.3 } />
                    <CollectibleItem lang={lang} position={ [-.3, -1, 8.5] } rotation={ .7 } />
                    <CollectibleItem lang={lang} position={ [-5.27, -1, 9] } rotation={ .8 } />
                    <CollectibleItem lang={lang} position={ [-8, -1, -4.5] } rotation={ .4 } />
                    <CollectibleItem lang={lang} position={ [.75, -1, -8.2] } rotation={ .1 } />
                </>

            </SceneCanvas>


        </>
    )
}
